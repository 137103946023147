import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { useLocation } from '@reach/router';
import { Container, Box, SimpleGrid, Flex } from '@chakra-ui/react';

import SEO from 'timelesstime-ui/components/seo';
import LeadInHeader from 'timelesstime-ui/components/lead-in-header';
import Html from 'timelesstime-ui/components/html';
import GoogleMap from 'timelesstime-ui/components/google-map';
import CallMeBack from 'timelesstime-ui/components/call-me-back';
import Heading from 'timelesstime-ui/components/heading';
import ButtonLink from 'timelesstime-ui/components/button-link';
import PageLayout from '../components/layout';

const areaNameRegex = new RegExp('{{areaName}}', 'g');
const placeNameRegex = new RegExp('{{placeName}}', 'g');

const replacePlaceholders = ({ content, areaName, placeName }) =>
  `${content}`
    .replace(areaNameRegex, areaName)
    .replace(placeNameRegex, placeName)
    .replace('The Uk', 'The UK');

const replaceNestedPlaceholders = ({ obj, areaName, placeName }) =>
  transform(
    obj,
    (result, value, key) => {
      if (Array.isArray(value)) {
        // eslint-disable-next-line no-param-reassign
        result[key] = value.map((v) =>
          replaceNestedPlaceholders({
            obj: v,
            areaName,
            placeName,
          }),
        );
      } else if (isObject(value)) {
        // eslint-disable-next-line no-param-reassign
        result[key] = replaceNestedPlaceholders({
          obj: value,
          areaName,
          placeName,
        });
      } else if (isString(value)) {
        // eslint-disable-next-line no-param-reassign
        result[key] = replacePlaceholders({
          content: obj[key],
          areaName,
          placeName,
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        result[key] = value;
      }
    },
    {},
  );

const ManagementAndHrConsultandsPage = ({
  pageContext: { areaName, placeName, locationData },
  data: { contentfulSpecialPage },
}) => {
  const page = replaceNestedPlaceholders({
    obj: contentfulSpecialPage,
    areaName,
    placeName,
  });
  const { pathname: canonicalPath } = useLocation();
  return (
    <PageLayout
      leadInHeader={
        <LeadInHeader heading={page.heading || page.title} imageType="tech" />
      }
    >
      <SEO
        title={`Management Consultants in ${placeName}`}
        keywords={['hr', 'management', 'consultants', areaName, placeName]}
        description={`We are Management Consultants in ${placeName}. Helping you get more from your people.`}
        canonicalPath={canonicalPath}
      />

      <Container maxW="container.lg" mt={8} mb={12}>
        <Html source={page.fieldstopContentHtml} headerLevelStart={1} />

        <Box textAlign="center" my={12}>
          <CallMeBack
            size="md"
            callBackText="Do you want help from our team of experts? Get in touch today!"
          />
        </Box>

        <SimpleGrid columns={[1, 1, 2]} spacing={4}>
          {[
            {
              title: page.textBlock1Title,
              image: page.textBlock1Image,
              content: page.fields.textBlock1ContentHtml,
            },
            {
              title: page.textBlock2Title,
              image: page.textBlock2Image,
              content: page.fields.textBlock2ContentHtml,
            },
            {
              title: page.textBlock3Title,
              image: page.textBlock3Image,
              content: page.fields.textBlock3ContentHtml,
            },
            {
              title: page.textBlock4Title,
              image: page.textBlock4Image,
              content: page.fields.textBlock4ContentHtml,
            },
          ].map(({ title, image, content }) => (
            <Box as="section" key={title}>
              <Heading as="h2">{title}</Heading>
              {image && image.gatsbyImageData && (
                <Flex justify="center" my={4}>
                  <GatsbyImage image={getImage(image)} alt={image.title} />
                </Flex>
              )}
              <Html source={content} headerLevelStart={2} />
            </Box>
          ))}
        </SimpleGrid>

        <Html
          mt={8}
          mb={8}
          source={page.fields.bottomContentHtml}
          headerLevelStart={1}
        />
      </Container>

      <Container as="footer" maxW="container.lg" mt={8} mb={12} align="center">
        <ButtonLink size="lg" colorScheme="orange" to="/services/">
          Have a look at the services we offer in {placeName}
        </ButtonLink>
      </Container>

      <Box height="400px" width="100%">
        <GoogleMap
          zoom={locationData.zoomLevel}
          center={{ lat: locationData.lat, lng: locationData.lng }}
          markerLocation={{ lat: locationData.lat, lng: locationData.lng }}
        />
      </Box>
    </PageLayout>
  );
};

export const query = graphql`
  query ManagementAndHrConsultandsPageQuery {
    contentfulSpecialPage(slug: { eq: "management-and-hr-consultants" }) {
      id
      title
      heading
      slug
      metaDescription
      textBlock1Image {
        title
        gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
      }
      textBlock1Title
      textBlock2Image {
        title
        gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
      }
      textBlock2Title
      textBlock3Image {
        title
        gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
      }
      textBlock3Title
      textBlock4Image {
        title
        gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
      }
      textBlock4Title
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        topContentHtml
        textBlock1ContentHtml
        textBlock2ContentHtml
        textBlock3ContentHtml
        textBlock4ContentHtml
        bottomContentHtml
      }
    }
  }
`;

export default ManagementAndHrConsultandsPage;
